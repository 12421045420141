import React from "react"

import { Container } from 'reactstrap' 
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-technology'
import redSafety from '../../assets/images/red-safety.png'
import worldIcon from '../../assets/images/world.png'
import pointCross from '../../assets/images/point-cross.png'
import techTruck from '../../assets/images/techTruck.png'
import tech1 from '../../assets/images/tech1.png'
import tech2 from '../../assets/images/tech2.png'
import tech3 from '../../assets/images/tech3.png'
import tech4 from '../../assets/images/tech4.png'
import tech5 from '../../assets/images/tech5.png'
import tech6 from '../../assets/images/tech6.png'
import tech7 from '../../assets/images/tech7.png'
import tech8 from '../../assets/images/tech9.png'
import tech9 from '../../assets/images/tech8.png'

export default () => (
  <LayoutEsp>
    <SEO title="Tecnologia" />
    <Slider title="TECNOLOGIA" />
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
			  </div>
			</div>
		</Container>
	</div>
	<div className="safety-background" >
		<Container className="pt-4">
			<div className="row block">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
				<img  className="redSafety" src={redSafety} alt="red icon safety"/> 
				<h4 className="safetyH"><b>SEGURIDAD</b> Y<br/>  <b>PROTECCION</b></h4> 
				<p className="aboutP">{'En todas nuestras cargas'}</p>  
				<img className="techTruckAbsolute" src={techTruck} />  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 safetyblock">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 techRelative">
						<h4 className="grayH marginSafety">{' '}</h4> 
						<p className="grayText">Gutierrez Trucking es una empresa que <b>monitorea de cerca</b> la seguridad 
												en la cadena de suministro y <b>constantemente asegura</b> toda la carga.  
						</p>
						<div className="row techAbsolute">
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech1} alt="service 1"/>
									<h4 className="techSub"><b>Monitoreo</b> y<br/> Seguimiento <b>24/7</b></h4>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech2} alt="service 2"/> 
									<h4 className="techSub"><b>Geolocalizacion</b> en Tiempo Real</h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech3} alt="service 4"/> 
									<h4 className="techSub"><b>Comunicacion Directa</b> con tu transportista (celular)</h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech4} alt="service 3"/> 
									<h4 className="techSub"><b>App</b> para comunicacion en tiempo real.</h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech5} alt="service 4"/>
									<h4 className="techSub"><b>Boton de Panico</b> para apagado de motor.</h4>
								</div> 
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech6} alt="service 6"/> 
									<h4 className="techSub"><b>Geofenses</b> toda la ruta.</h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech7} alt="service 3"/> 
									<h4 className="techSub">Seguimiento por <b>Email</b></h4>
								</div>
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech8} alt="service 4"/>
									<h4 className="techSub"><b>Seguimiento en vivo.</b></h4>
								</div> 
							</div> 
							<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
								<div className="row cert-white"> 
									<img  src={tech9} alt="service 6"/> 
									<h4 className="techSub">Electronic data interchange (<b>EDI</b>)</h4>
								</div>
							</div> 
						</div>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div>
	<div className="red-tech-background" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 techRelative2">
				<img className="techAbsolute2" src={pointCross} alt="service 4"/>  
			  </div> 
			</div> 
		</Container>
	</div>  
  </LayoutEsp>
)
